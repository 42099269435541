@tailwind base;
@tailwind components;
@tailwind utilities;



.mobile-logo{
    display: none;
}


@media (max-width: 768px) {
    .mobile-logo {
        display: block;
    }
    .desktop-logo{
        display:  none;
    }
}
